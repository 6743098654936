/* 
 * @Author: 曹俊杰 
 * @Date: 2022-03-18 15:07:21
 * @Module: 公告详情
 */
 <template>
  <div class="NoticeDetail">
    <el-card shadow="never" :loading="loading" :body-style="{minHeight: 'calc(100vh - 190px)'}">
      <div slot="header" class="header">
        <el-button type="text" class="back" icon="el-icon-arrow-left" @click="$router.go(-1)">返回</el-button>
        <div class="tittle">{{title}}</div>
      </div>
      <h5>{{createdAt|timeFormat("yyyy-mm-dd MM:ss")}}</h5>
      <div v-html="content" class="content"></div>
    </el-card>
  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {
      loading: false,
      content: '',
      createdAt: 0,
      title: ''
    };
  },
  mounted () { },
  methods: {
    async getNoticeDetail (noticeId) {
      this.loading = true
      const { data, status } = await this.$api.getNoticeDetail({ noticeId })
      if (status === 200) {
        this.content = data.content
        this.createdAt = data.createdAt
        this.title = data.title
      }
      this.loading = false
    }
  },
  watch: {
    "$route.params": {
      handler ({ id }) {
        this.getNoticeDetail(id)
      },
      immediate: true
    }
  }
};
 </script>
 <style lang='scss' scoped>
@import "~@/assets/style/var.scss";
h1 {
  // color: $--color-primary;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
}
h5 {
  font-size: 12px;
  color: #aaa;
  text-align: center;
  border-bottom: 1px solid #ebeef5;
  padding-bottom: 12px;
}
.header {
  position: relative;
  display: flex;
  justify-content: center;
  .tittle {
    line-height: 50px;
    font-size: 16px;
    text-align: center;
    font-family: PingFangSC-Medium, PingFang SC;

    width: 85%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis; /* for Opera */
  }
  .back {
    position: absolute;
    left: 24px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
.content {
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
  & /deep/ p {
    font-size: 14px;
    text-align: justify;
    line-height: 26px;
    color: #666;
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  & /deep/ img {
    margin-bottom: 20px;
  }
}
</style>